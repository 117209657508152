@charset "UTF-8";
.ng-select.ng-select-opened>.ng-select-container{background:#fff;border-color:#b3b3b3 #ccc #d9d9d9}
.ng-select.ng-select-opened>.ng-select-container:hover{box-shadow:none}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow{top:-2px;border-color:transparent transparent #999;border-width:0 5px 5px}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover{border-color:transparent transparent #333}
.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container{border-bottom-right-radius:0;border-bottom-left-radius:0}
.ng-select.ng-select-opened.ng-select-top>.ng-select-container{border-top-right-radius:0;border-top-left-radius:0}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{border-color:#007eff;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 3px rgba(0,126,255,0.1)}
.ng-select.ng-select-disabled>.ng-select-container{background-color:#f9f9f9}
.ng-select .ng-has-value .ng-placeholder{display:none}
.ng-select .ng-select-container{color:#333;background-color:#fff;border-radius:4px;border:1px solid #ccc;min-height:36px;align-items:center}
.ng-select .ng-select-container:hover{box-shadow:0 1px 0 rgba(0,0,0,0.06)}
.ng-select .ng-select-container .ng-value-container{align-items:center;padding-left:10px}
[dir="rtl"] .ng-select .ng-select-container .ng-value-container{padding-right:10px;padding-left:0}
.ng-select .ng-select-container .ng-value-container .ng-placeholder{color:#999}
.ng-select.ng-select-single .ng-select-container{height:36px}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{top:5px;left:0;padding-left:10px;padding-right:50px}
[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{padding-right:10px;padding-left:50px}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value{background-color:#f9f9f9;border:1px solid #e6e6e6}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label{padding:0 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container{padding-top:5px;padding-left:7px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container{padding-right:7px;padding-left:0}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{font-size:.9em;margin-bottom:5px;background-color:#ebf5ff;border-radius:2px;margin-right:5px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{margin-right:0;margin-left:5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled{background-color:#f9f9f9}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{padding-left:5px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{padding-left:0;padding-right:5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label{display:inline-block;padding:1px 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{display:inline-block;padding:1px 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover{background-color:#d1e8ff}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{border-right:1px solid #b8dbff}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{border-left:1px solid #b8dbff;border-right:none}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{border-left:1px solid #b8dbff}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{border-left:0;border-right:1px solid #b8dbff}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding:0 0 3px 3px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding:0 3px 3px 0}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{top:5px;padding-bottom:5px;padding-left:3px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{padding-right:3px;padding-left:0}
.ng-select .ng-clear-wrapper{color:#999}
.ng-select .ng-clear-wrapper:hover .ng-clear{color:#D0021B}
.ng-select .ng-spinner-zone{padding:5px 5px 0 0}
[dir="rtl"] .ng-select .ng-spinner-zone{padding:5px 0 0 5px}
.ng-select .ng-arrow-wrapper{width:25px;padding-right:5px}
[dir="rtl"] .ng-select .ng-arrow-wrapper{padding-left:5px;padding-right:0}
.ng-select .ng-arrow-wrapper:hover .ng-arrow{border-top-color:#666}
.ng-select .ng-arrow-wrapper .ng-arrow{border-color:#999 transparent transparent;border-style:solid;border-width:5px 5px 2.5px}
.ng-dropdown-panel{background-color:#fff;border:1px solid #ccc;box-shadow:0 1px 0 rgba(0,0,0,0.06);left:0}
.ng-dropdown-panel.ng-select-bottom{top:100%;border-bottom-right-radius:4px;border-bottom-left-radius:4px;border-top-color:#e6e6e6;margin-top:-1px}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child{border-bottom-right-radius:4px;border-bottom-left-radius:4px}
.ng-dropdown-panel.ng-select-top{bottom:100%;border-top-right-radius:4px;border-top-left-radius:4px;border-bottom-color:#e6e6e6;margin-bottom:-1px}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child{border-top-right-radius:4px;border-top-left-radius:4px}
.ng-dropdown-panel .ng-dropdown-header{border-bottom:1px solid #ccc;padding:5px 7px}
.ng-dropdown-panel .ng-dropdown-footer{border-top:1px solid #ccc;padding:5px 7px}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup{-webkit-user-select:none;user-select:none;padding:8px 10px;font-weight:500;color:rgba(0,0,0,0.54);cursor:pointer}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled{cursor:default}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked{background-color:#f5faff}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked{background-color:#ebf5ff;font-weight:600}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{background-color:#fff;color:rgba(0,0,0,0.87);padding:8px 10px}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{color:#333;background-color:#ebf5ff}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label{font-weight:600}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{background-color:#f5faff;color:#333}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled{color:#ccc}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-left:22px}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-right:22px;padding-left:0}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{font-size:80%;font-weight:400;padding-right:5px}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{padding-left:5px;padding-right:0}
[dir="rtl"] .ng-dropdown-panel{direction:rtl;text-align:right}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: "foco", sans-serif;
}
html,
body,
#app {
  min-height: 100vh;
  overflow-x: hidden !important;
  margin: 0;
}
body {
  display: flex;
  flex-direction: column;
  font-family: "foco", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
body.has-overlay {
  overflow: hidden;
}
input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a {
  text-decoration: none;
  color: #201e1f;
  font-size: 16px;
  text-decoration: none;
  transition: 0.2s all ease;
  cursor: pointer;
}
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
app-item-how-works {
  width: 100%;
}
ng-dropdown-panel {
  z-index: 1051;
}
.dd-hide {
  display: none;
}
.dd-hide-force {
  visibility: hidden !important;
}
@media (max-width: 767px) {
  .hide-mobile {
    display: none !important;
  }
}
@media (min-width: 767px) {
  .hide-desktop {
    display: none !important;
  }
}
.dd-l-header-slim__back, .dd-l-header-slim__content, .dd-l-header-slim__top-bar, .dd-l-header__list li:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}
#app {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.dd-l-header-slim__back app-arrow-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  text-decoration: none;
  outline: 0;
}
button * {
  cursor: pointer;
}
@font-face {
  font-family: "foco";
  src: url("/assets/font/foco/acerfoco_smbd-webfont.woff") format("woff"), url("/assets/font/foco/acerfoco_smbd-webfont.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "foco";
  src: url("/assets/font/foco/acerfoco_smbdit-webfont.woff") format("woff"), url("/assets/font/foco/acerfoco_smbdit-webfont.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "foco";
  src: url("/assets/font/foco/acerfoco_ltit-webfont.woff") format("woff"), url("/assets/font/foco/acerfoco_ltit-webfont.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "hero";
  src: url("/assets/font/hero/Hero-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "hero";
  src: url("/assets/font/hero/Hero.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "icomoon";
  src: url("/assets/font/icomoon/icomoon.eot?nm6dp");
  src: url("/assets/font/icomoon/icomoon.eot?nm6dp#iefix") format("embedded-opentype"), url("/assets/font/icomoon/icomoon.ttf?nm6dp") format("truetype"), url("/assets/font/icomoon/icomoon.woff?nm6dp") format("woff"), url("/assets/font/icomoon/icomoon.svg?nm6dp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-up:before {
  content: "";
}
.icon-close:before {
  content: "";
}
.icon-map-color-full .path1:before {
  content: "";
  color: #ffda2d;
}
.icon-map-color-full .path2:before {
  content: "";
  margin-left: -0.7373046875em;
  color: #80aef8;
}
.icon-map-color-full .path3:before {
  content: "";
  margin-left: -0.7373046875em;
  color: #4086f4;
}
.icon-map-color-full .path4:before {
  content: "";
  margin-left: -0.7373046875em;
  color: #59c36a;
}
.icon-map-color-full .path5:before {
  content: "";
  margin-left: -0.7373046875em;
  color: #00a66c;
}
.icon-map-color-full .path6:before {
  content: "";
  margin-left: -0.7373046875em;
  color: #fdbf00;
}
.icon-map-color-full .path7:before {
  content: "";
  margin-left: -0.7373046875em;
  color: #4086f4;
}
.icon-map-color-full .path8:before {
  content: "";
  margin-left: -0.7373046875em;
  color: #4175df;
}
.icon-map-color-full .path9:before {
  content: "";
  margin-left: -0.7373046875em;
  color: #f03800;
}
.icon-heart:before {
  content: "";
}
.icon-map:before {
  content: "";
}
.icon-arrow-down:before {
  content: "";
}
.icon-arrow-left:before {
  content: "";
}
.icon-home:before {
  content: "";
}
.icon-Phone:before {
  content: "";
}
.icon-search:before {
  content: "";
}
.icon-pin:before {
  content: "";
}
.icon-WhatsApp:before {
  content: "";
}
.ng-select * {
  cursor: pointer !important;
}
.ng-select .ng-select-container {
  cursor: pointer !important;
  border-radius: 8px !important;
  border: none !important;
  box-sizing: border-box;
  box-shadow: none !important;
  color: #062f6d !important;
  display: block;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  min-height: 46px !important;
  padding: 14px 16px;
  outline: none;
  width: 100%;
  -webkit-appearance: none;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #062f6d transparent transparent !important;
  border-width: 8px 7px 0px !important;
}
.ng-placeholder {
  color: #062f6d !important;
  font-family: "foco", sans-serif !important;
  font-style: italic !important;
  font-weight: 200 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}
.ng-dropdown-panel.ng-select-bottom {
  margin-top: -4px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0 !important;
  bottom: 0;
  margin: auto;
  height: -webkit-max-content;
  height: max-content;
}
.ng-select .ng-select-container .ng-value-container .ng-input > input {
  color: #062f6d !important;
  font-family: "foco", sans-serif !important;
  font-style: italic !important;
  font-weight: 200 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}
.ng-clear-wrapper {
  display: none !important;
}
.dd-l-header {
  background: #062f6d;
  width: 100%;
  z-index: 1;
}
@media (min-width: 1200px) {
  .dd-l-header {
    height: 120px;
  }
}
.dd-l-header__wrapper {
  width: 100%;
  max-width: calc(1190px + 48px);
  margin: 0 auto;
  padding: 0 24px;
  bottom: -20px;
  display: none;
  position: relative;
  z-index: 1;
}
@media (min-width: 1200px) {
  .dd-l-header__wrapper {
    display: block;
    padding: 28px 40px;
  }
}
.dd-l-header__logo {
  max-width: 148px;
  max-height: 32px;
  object-fit: contain;
  margin-left: auto;
}
@media (min-width: 1200px) {
  .dd-l-header__logo {
    max-width: 192px;
    margin-left: 0;
  }
}
.dd-l-header__top-bar {
  width: 100%;
  max-width: calc(1190px + 48px);
  margin: 0 auto;
  padding: 0 24px;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  padding: 0 24px;
}
@media (min-width: 1200px) {
  .dd-l-header__top-bar {
    flex-wrap: nowrap;
    background: #062f6d;
  }
}
.dd-l-header__top-bar__title {
  color: #fff;
  font-size: 18px;
  font-style: italic;
  max-width: 100%;
  order: 2;
  text-align: center;
  width: 100%;
}
@media (min-width: 1200px) {
  .dd-l-header__top-bar__title {
    margin-left: auto;
    max-width: 250px;
    order: 0;
    margin-right: 60px;
  }
}
.dd-l-header__top-bar .logo {
  width: 160px;
}
@media (min-width: 1200px) {
  .dd-l-header__top-bar .logo {
    width: 300px;
  }
}
.dd-l-header__list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}
@media (min-width: 1200px) {
  .dd-l-header__list {
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .dd-l-header__list li:not(:last-child) {
    margin-right: 38px;
  }
}
.dd-l-header__list li:last-child a {
  padding: 14px;
  border-radius: 12px;
  font-size: 16px;
  color: #009344;
  font-weight: 700;
  background-color: transparent;
  border: 1px solid #009344;
  border-radius: 40px;
}
.dd-l-header__list li:first-child {
  width: 100%;
  margin-bottom: 24px;
  margin-right: 0;
}
@media (min-width: 1200px) {
  .dd-l-header__list li:first-child {
    margin-bottom: 0;
    margin-right: 44px;
    width: -webkit-max-content;
    width: max-content;
  }
}
.dd-l-header__list a {
  cursor: pointer;
  color: #062f6d;
  font-size: 14px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .dd-l-header-slim {
    background-color: #28458b;
  }
}
.dd-l-header-slim__top-bar {
  background: #062f6d;
  color: #fff;
  height: 30px;
}
.dd-l-header-slim__wrapper {
  width: 100%;
  max-width: calc(1190px + 48px);
  margin: 0 auto;
  padding: 0 24px;
  padding: 0;
}
.dd-l-header-slim__content {
  justify-content: space-between;
  padding: 28px;
}
.dd-l-header-slim__logo {
  max-width: 128px;
  max-height: 40px;
  object-fit: contain;
}
@media (min-width: 768px) {
  .dd-l-header-slim__logo {
    max-width: 250px;
    max-height: 100px;
    object-fit: contain;
  }
}
.dd-l-header-slim__back {
  background: #009344;
  border-radius: 26px;
  color: #fff;
  font-size: 17px;
  font-family: foco;
  font-weight: 800;
  padding: 14px 46px;
  position: relative;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .dd-l-header-slim__back {
    padding-left: 62px;
  }
}
.dd-l-header-slim__back app-arrow-left {
  left: 14px;
  position: absolute;
}
.dd-l-footer {
  background-color: #062f6d;
  height: 23px;
}